<template>
  <div class="" style="width: 100%;">
    <v-container>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.push({path: '/purchasing'})}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>View Signed {{' '+this.singular}}</h1>
        </v-col>
        <v-col class="col-md-4">
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col cols="8" style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">
          <div class="d-flex flex-column align-space-between justify-space-between" style="height: 800px;">
            <div style="width: 100%;">
              <!-- header -->
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column" style="width: 50%; margin-bottom: 10px;">
                  <!-- <img src="" alt=""> -->
                  <h1 class="d-flex flex-row align-center">
                    <div v-if="purchaseOrder.status==undefined" style="margin-right: 10px; background-color: red; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="purchaseOrder.status=='draftSaved'" style="margin-right: 10px; background-color: green; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="purchaseOrder.status=='draftUnsaved'" style="margin-right: 10px; background-color: orange; border-radius: 15px; width: 15px; height: 15px;"></div>
                    Purchase Order<span style="margin-left: 10px;" v-if="!this.isNewPO">#{{purchaseOrder.id}}</span>
                  </h1> 
                </div>
                <div class="d-flex flex-column align-end" style="width: 50%;">
                  <span>{{formattedDate(purchaseOrder.createdAt)}}</span>
                </div>
              </div>
              <div style="width: 100%; margin-top: 10px;" class="d-flex flex-row justify-space-between">
                <div v-if="purchaseOrder.supplierId" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Supplier</b>
                  </span>
                  <div class="d-flex flex-column">
                    <span>{{purchaseOrder.supplierInfo.companyName}}</span>
                    <span>{{purchaseOrder.supplierInfo.address}}</span>
                    <span>{{purchaseOrder.supplierInfo.phone}}</span>
                    <span>{{purchaseOrder.supplierInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Supplier</b></span>
                  <span>-</span>
                </div>
                <div v-if="purchaseOrder.shipToInfo" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Ship To</b>
                  </span>
                  <div class="d-flex flex-column">
                    <span>{{purchaseOrder.shipToInfo.companyName}}</span>
                    <span>{{purchaseOrder.shipToInfo.address}}</span>
                    <span>{{purchaseOrder.shipToInfo.phone}}</span>
                    <span>{{purchaseOrder.shipToInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Ship To</b></span>
                  <span>-</span>
                </div>
              </div>
            </div>
            <div style="height: 500px; border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px; padding: 20px; overflow-y: scroll;">
              <!-- body -->
                <v-simple-table style="height: 100%; background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Item No.
                      </th>
                      <th class="text-left">
                        Item Name
                      </th>
                      <th class="text-left">
                        Description
                      </th>
                      <th class="text-left">
                        Unit Price
                      </th>
                      <th class="text-left">
                        Qty
                      </th>
                      <th class="text-right">
                        Line Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in purchaseOrder.purchaseOrderItems" :key="item.id">
                      <td>{{item.sku || '-'}}</td>
                      <td>{{item.name}}</td>
                      <td>{{item.description}}</td>
                      <td>{{formatPrice(item.price)}}</td>
                      <td>{{item.quantity}}</td>
                      <td class="d-flex flex-row justify-end align-center"><span v-if="item.lineTotal">{{formatPrice(item.lineTotal)}}</span><span v-else>-</span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
              <div class="d-flex flex-column align-end">
                <b>Subtotal</b>
                <b>Tax ({{this.taxRate}}%)</b>
                <b>Total</b>
              </div>
              <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
                <span class="d-flex flex-column align-start">
                  <div>{{formatPrice(purchaseOrder.subtotal)}}</div>
                  <div>{{formatPrice(purchaseOrder.subtotal*(taxRate/100)||0)}}</div>
                  <div>{{formatPrice(purchaseOrder.grandTotal)}}</div>
                </span>
              </div>
            </div>
            <div v-if="!loader" style="margin-top: 20px; height: 100px;" class="d-flex flex-column">
              <!-- footer -->
              <span>Document Signature: {{purchaseOrder.sealedDocument.signature}} <span v-if="this.documentVerified">✅</span><span v-else>❌</span></span>
              <span>Document Signed On: {{formattedDate(purchaseOrder.sealedDocument.sealedOn)}}</span>
              <span>Document Signed By: {{purchaseOrder.sealedDocument.sealedBy}}✅</span>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.secondaryPlural}}</h1>
              <v-btn
                  v-if="purchaseOrder.status===1"
                  class="mx-2"
                  fab
                  small
                  dark
                  color="indigo"
                  @click="createA"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="receivingReports"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
                <template v-slot:item.status="{ item }">
                  <span>{{ parseRRStatus(item.status) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-container>
    <v-dialog v-model="createDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Receiving Report
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" text @click="cancelCreate">
            Cancel
          </v-btn>
          <v-btn color="success" @click="createB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        data: {},
        userTypes: [],
        products: [],

        singular: "Purchase Order",
        singularLower: "purchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",

        secondarySingular: "Receiving Report",
        secondarySingularLower: "receivingreport",
        secondaryPlural: "Receiving Reports",
        secondaryPluralLower: "receivingreports",

        suppliers: [],
        supplierProducts: [],
        purchaseOrder: {
          sealedDocument: {},
          purchaseOrderItems: [], 
          subtotal: 0, 
          grandTotal: 0, 
          supplierInfo: {}, 
          shipToInfo: {
            companyName: "Teelucksingh's Furniture Limited",
            address: "57 High Street, Princes Town",
            phone: '3332123',
            email: 'teelucksinghs@gmail.com',
        }},

        receivingReports: [],

        headers: [
          { text: 'ID', align: 'start', sortable: true, value: 'id' },
          { text: 'Date Created', align: 'start', sortable: true, value: 'createdAt' },
          { text: 'Date Saved', align: 'start', sortable: true, value: 'updatedAt' },
          { text: 'Last Updated By', align: 'start', sortable: true, value: 'employee' },
          { text: 'Status', align: 'start', sortable: true, value: 'status' },
        ],

        valid: false,
        taxRate: 10,
        created: false,
        isNewPO: "",
        supplierEditing: false,
        shipToEditing: false,
        tempSupplierInfo: {},
        tempShipToInfo: {},
        renderKey: 0,
        deleteDialog: false,
        documentVerified: null,
        createDialog: {
          isOpen: false,
          confirmed: false
        }
      }
    },
    async mounted(){
      try{
        this.loader = true;
        let po = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
        if(po.data.error) throw po.data.error
        this.purchaseOrder = po.data.data
        if(!this.purchaseOrder.sealedDocument) await this.$router.push({path: `/purchaseorders/create/${this.$route.params.id}`})

        let rr = await axios.get(`${this.getEndpoint}/api/${this.secondaryPluralLower}/byPO/${this.$route.params.id}`);
        if(rr.data.error) throw rr.data.error
        this.receivingReports = rr.data.data
        this.receivingReports.forEach(row => {
          row.createdAt = moment(row.createdAt).format("DD/MM/YYYY hh:mm:ss");
          row.updatedAt = moment(row.updatedAt).format("DD/MM/YYYY hh:mm:ss");

          row.employee = row.User.firstName+" "+row.User.lastName;
        });

        let verified = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/verify/${this.$route.params.id}`);
        if(verified.data.error) throw verified.data.error
        this.documentVerified = verified.data.data
        this.updateTotals()
        console.log(this.purchaseOrder)
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    computed: {
      ...mapGetters(['getEndpoint', "getId"])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      updateTotals(){
        this.purchaseOrder.purchaseOrderItems.forEach(item=>item.lineTotal = item.quantity*item.price)
        this.purchaseOrder.subtotal = this.purchaseOrder.purchaseOrderItems.reduce((a,x)=>a+x.lineTotal,0)
        this.purchaseOrder.grandTotal = ((this.taxRate/100)*this.purchaseOrder.subtotal) + this.purchaseOrder.subtotal
        this.$forceUpdate()
      },
      formattedDate(d){
        return moment(d).format("DD/MM/YYYY hh:mm:ss")
      },
      rowClick(row){
        this.$router.push({ path: `/${this.secondaryPluralLower}/view/${row.id}`})
      },
      createA(){
        this.createDialog.isOpen = true
      },
      cancelCreate(){
        this.createDialog.isOpen = false;
      },
      async createB(){
        try {
          // this.$router.push({ path: `/${this.secondaryPluralLower}/po/${this.purchaseOrder.id}/create`})
          let res = await axios.post(`${this.getEndpoint}/api/receivingreports`, {poId: this.$route.params.id, employeeId: this.getId})
          if(res.data.error) throw res.data.error
          await this.$router.push({ path: `/receivingreports/view/${res.data.data.id}`})
          this.createDialog.isOpen = false
        } catch (error) {
         console.error(error)
          this.snack(error.msg || error, "error");
         this.createDialog.isOpen = false
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      parseRRStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
      }
    }
  }
</script>
