<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>{{this.plural}}</h1>
          <v-btn
            v-if="this.isAllowed('user', 'c')"
            class="mx-2"
            fab
            small
            dark
            color="indigo"
            @click="create"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn class="mx-2" dark color="info" to="/usertypes">User Types</v-btn>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="-1"
              class="elevation-1"
              @click:row="rowClick"
            >
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import { mapGetters } from "vuex"
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: 'First Name', align: 'start', sortable: true, value: 'firstName' },
          { text: 'Last Name', sortable: true, value: 'lastName' },
          { text: 'Address', value: 'address' },
          { text: 'Date of Birth', sortable: true, value: 'dateOfBirth' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', sortable: true, value: 'email' },
          { text: 'Username', sortable: true, value: 'username' },
          { text: 'User Type', value: 'UserType.name' },
          { text: 'Building', value: 'metadata.printNode' },
        ],
        editMode: false,
        data: [],
        singular: "User",
        singularLower: "user",
        plural: "Users",
        pluralLower: "users"
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error;

          this.data = res.data.data

          this.data.forEach(user => {
            if(user.dateOfBirth){
              user.dateOfBirth = new Date(user.dateOfBirth).toISOString().substring(0, 10);
            }
            if(user.hireDate){
              user.hireDate = new Date(user.hireDate).toISOString().substring(0, 10);
            }
            if(user.fireDate){
              user.fireDate = new Date(user.fireDate).toISOString().substring(0, 10);
            }
          });
        } catch (error) {
            console.error(error);
            this.snack(error.msg || error, "error");
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getUser', 'getId', 'getToken', 'isAllowed', 'getEndpoint'])
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      create(){
        this.$router.push({ path: `/${this.pluralLower}/create`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      }
    }
  }
</script>
